export const endpoint = "https://qbmedia.b-cdn.net/qbmedia/"

export const bizDetails = {
    name: "شركه سهم للتوصيل",
    description: "بنوصلك طلبك زي السهم",
    socialBtns : {
        call: "اتصل",
        wsp: "واتساب",
        ig: "انستاغرام",
        fb: "فيسبوك",
        website: "الموقع",
        tiktok:"تيكتوك",
        app:"التطبيق",
        menu:"كاتالوج",
        waze:"وييز",
        mail: "مييل",
        review: "قييمني",
        meeting: "عين موعد"
    },
    saveBtnText: "ضيفني لجهات الاتصال",
    orderNowText: "أطلب منتجك الأن"
}

export const bizDetails_He = {
    name: "חברת סהם למשלוחים",
    description: "משלוחים לכל בעלי העסקים",
    socialBtns : {
        call: "שיחה",
        wsp: "וואטספ",
        ig: "אינסטגרם",
        fb: "פייסבוק",
        website: "האתר",
        tiktok:"טיקטוק",
        app:"אפלקציה",
        menu:"קטלוג",
        waze:"וואיז",
        mail: "מייל",
        review: "דרג אותנו",
        meeting: "פגישה"
    },
    saveBtnText: "הוסף אותנו לאנשי קשר",
    orderNowText: "הזמן שירות עכשיו"
}

export const bizDetails_En = {
    name: "Sehem Delivery",
    description: "You sell and we deliver. Fast.",
    socialBtns : {
        call: "Call Us",
        wsp: "WSP",
        ig: "IG",
        fb: "FB",
        website: "Website",
        tiktok:"Tiktok",
        app:"Our App",
        menu:"catalog",
        waze:"Waze",
        mail: "Mail",
        review: "Rate Us",
        meeting: "Meet Us"
    },
    saveBtnText: "Add Us To Contacts",
    orderNowText: "Order a Service"
}

export const contact = {
    name: "Sehem Delivery",
    phone: "972534637888",
    email: "info@sehem.delivery",
    website: "https://sehem.delivery/",
    ig:"sehem.delivery/",
    fb:"profile.php?id=61565152387280/",
    tiktok:"@sehemdelivery",
    menu:"https://qbmedia.co.il/",
    waze: "waze.com",
};

export const schedule = {
    link:"https://.com",
    title:"קביעת פגישה אונליין",
}

export const footer = {
    desclaimer: "برمجه وتصميم",
    copywrites: "كيوبي ميديا"
}

export const socialAccounts = {
    wsp:`https://api.whatsapp.com/send?phone=${contact.phone}`,
    ig:`http://instagram.com/${contact.ig}`,
    fb:`https://facebook.com/${contact.fb}`,
    tiktok:`https://www.tiktok.com/${contact.tiktok}`,
    app:`https://www.apple.com/${contact.tiktok}`,
}