import React, { useEffect, useState } from 'react';
import './App.css';
import Hero from './components/Hero/Hero';
import SocialIcons from './components/SocialIcons/SocialIcons';
import SaveContactBtn from './components/SaveContactBtn/SaveContactBtn';
import Footer from './components/Footer/Footer';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import { bizDetails, bizDetails_En, bizDetails_He, footer } from './utils/config';

function isHebrew() {
  return /he/i.test(navigator.language);
}

function isEnglish() {
  return /en/i.test(navigator.language);
}

function App() {
  let defaultBusinessInfo = bizDetails; // Default to Arabic
  const [businessInfo, setBusinessInfo] = useState(defaultBusinessInfo);
  const [inIsrael, setInIsrael] = useState(null); // default to null until API call is complete

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        if (data.country === 'IL') {
          setInIsrael(true);
        } else {
          setInIsrael(false);
        }
      })
      .catch(error => console.log(error));
  }, []);

  // Update business info based on detected language and location
  useEffect(() => {
    if (inIsrael === null) {
      return;
    }

    let newBusinessInfo = bizDetails;

    if (isHebrew()) {
      newBusinessInfo = bizDetails_He;
    } else if (isEnglish()) {
      newBusinessInfo = inIsrael ? bizDetails : bizDetails_En;
    }

    setBusinessInfo(newBusinessInfo);
  }, [inIsrael]);

  const handleLanguageChange = (language) => {
    let newBusinessInfo;

    if (language === 'he') {
      newBusinessInfo = bizDetails_He;
    } else if (language === 'en') {
      newBusinessInfo = inIsrael ? bizDetails : bizDetails_En;
    } else {
      newBusinessInfo = bizDetails; // Default to Arabic
    }

    setBusinessInfo(newBusinessInfo);
  };

  // Render null until the API call is complete and the country is determined
  if (inIsrael === null) {
    return null;
  }

  return (
    <div className="App">
      <LanguageSelector onLanguageChange={handleLanguageChange} />
      <Hero bizDetails={businessInfo} />
      <SocialIcons bizDetails={businessInfo} />
      <SaveContactBtn bizDetails={businessInfo} />
      <Footer footer={footer} />
    </div>
  );
}

export default App;